var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        for=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\"\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        for=\"r-"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"input_name") : depth0), depth0))
    + "\"\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " align-items-center";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            id=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\"\n            name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\"\n            value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\"\n"
    + ((stack1 = __default(require("../js/helpers/ifans.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answer") : depth0),(depth0 != null ? lookupProperty(depth0,"input_id") : depth0),(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"ifans","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":20,"column":22}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "            checked=\"checked\"\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            id=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_name") : depth0), depth0))
    + "\"\n            "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"onclick") : depth0), depth0)) != null ? stack1 : "")
    + "\n            name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_name") : depth0), depth0))
    + "\"\n"
    + ((stack1 = __default(require("../js/helpers/ifans.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answer") : depth0),(depth0 != null ? lookupProperty(depth0,"input_name") : depth0),(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"ifans","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":28,"column":22}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return " xor-element";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            data-trailing-popup=\"r-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"trailer") : depth0)) != null ? lookupProperty(stack1,"input_id") : stack1), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\"\n            data-target=\"#r-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"trailer") : depth0)) != null ? lookupProperty(stack1,"input_idx") : stack1), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\"\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"open_trailer",(depth0 != null ? lookupProperty(depth0,"trailer") : depth0),{"name":"partial","hash":{"element_id":(depth0 != null ? lookupProperty(depth0,"input_id") : depth0),"code":(depth0 != null ? lookupProperty(depth0,"code") : depth0)},"data":data,"loc":{"start":{"line":40,"column":12},"end":{"line":40,"column":78}}})) != null ? stack1 : "")
    + "\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"popup",(depth0 != null ? lookupProperty(depth0,"trailer") : depth0),{"name":"partial","hash":{"code":(depth0 != null ? lookupProperty(depth0,"code") : depth0)},"data":data,"loc":{"start":{"line":45,"column":4},"end":{"line":47,"column":7}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"question-response-item w-choice-base\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\">\n    <label\n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"input_type") : depth0),"radio",{"name":"ifequal","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":20}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"input_type") : depth0),"checkbox",{"name":"ifequal","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":20}}})) != null ? stack1 : "")
    + "        class=\"response-label"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"text") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":29},"end":{"line":9,"column":75}}})) != null ? stack1 : "")
    + "\"\n        >\n        <input\n            tabindex=\"0\"\n            type=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_type") : depth0), depth0))
    + "\"\n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"input_type") : depth0),"radio",{"name":"ifequal","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":21,"column":24}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"input_type") : depth0),"checkbox",{"name":"ifequal","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":29,"column":24}}})) != null ? stack1 : "")
    + "            class=\"top-level"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"xor") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":28},"end":{"line":30,"column":66}}})) != null ? stack1 : "")
    + "\"\n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"trailer") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"popup",{"name":"ifequal","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":34,"column":24}}})) != null ? stack1 : "")
    + "            />\n        <span class=\"label-inner\">\n            <span class=\"label-text\" id=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "-label\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"text") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":72},"end":{"line":37,"column":101}}})) != null ? stack1 : "")
    + "</span>\n"
    + ((stack1 = __default(require("../js/helpers/ifindexof.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"trailer") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"open","==",0,{"name":"ifindexof","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":12},"end":{"line":41,"column":26}}})) != null ? stack1 : "")
    + "        </span>\n    </label>\n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"trailer") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"popup",{"name":"ifequal","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":4},"end":{"line":48,"column":16}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});