(function (widgets) {
  var Single = widgets.Widget.extend(
    {
      render: function render() {
        Single.__super__.render.call(this);
        var responseElements = (this._responses =
          this._createResponseElements());
        var responseListContainer = this.$el.find('.question-response-list');
        this._container = responseListContainer;
        var config = {
          columns: this._object.columns,
          minColumns: this._object.min_columns,
          wrap: this._object.wrap,
          horizontal: this._object.horizontal,
          autoadvance: this._object.autoadvance,
          onLayout: $.proxy(this, '_onLayout'),
        };
        this.layout = new Gryphon.widgets.layout.DynamicRowLayout(
          responseListContainer,
          responseElements,
          config
        );
        this.layout.render();

        if (config.autoadvance) {
          this.$el.on('click', 'input', click_next);
          $('.modal-backdrop').remove();
        }
        if (
          this._object.custom_order !== '' &&
          this._object.custom_order !== 'None' &&
          this._object.type !== 'language'
        ) {
          window.create_custom_order(
            this,
            this._object.type,
            this._object.custom_order
          );
        }

        // Accessible element for previous answers (multiple)
        if (this._object.answer && this._object.answer.length > 0) {
          this._object.answer.forEach((value, idx) => {
            const response = this._object.response_options.find(
              (element) => element.code === value
            );
            if (response) {
              $(`#${this._object.input_id}_note`).append(
                idx + 1 >= this._object.answer.length &&
                  this._object.answer.length > 1
                  ? `and ${response.text}`
                  : this._object.answer.length > 1
                  ? `${response.text}, `
                  : response.text
              );
            }
          });
        }

        var exclusiveInputs = this._object.response_options.filter(function (
          el
        ) {
          return el.options && el.options.xor === true;
        });

        var allNormalInputsText = this.$el
          .find('input[type="text"],textarea')
          .not('.xor-element');

        var allNormalInputsSelect = this.$el
          .find('input:not([type="text"])')
          .not('.xor-element');

        var hideResponseInputs = function () {
          allNormalInputsText
            .addClass('hidden-response')
            .attr('readonly', true);
          allNormalInputsSelect.attr('readonly', true);
        };

        var showResponseInputs = function () {
          allNormalInputsText
            .removeClass('hidden-response')
            .attr('readonly', false);
          allNormalInputsSelect.attr('readonly', false);
        };

        var enableResponseInput = function (element) {
          var responseInput = element
            .closest('.question-response-item')
            .find('input[type="text"],textarea');
          responseInput.removeClass('hidden-response').attr('readonly', false);
        };

        allNormalInputsSelect.on('click', function () {
          showResponseInputs();
        });

        allNormalInputsText.on('click', function () {
          if ($(this).val()) {
            showResponseInputs();
          }
        });

        var that = this;

        exclusiveInputs.forEach(function (exclusiveInput) {
          var exclusiveInputSelector = `input#r-${that._object.input_id}-${exclusiveInput.code}`;
          var exclusiveInputEl = that.$el.find(exclusiveInputSelector);

          if (exclusiveInputEl.is(':checked')) {
            hideResponseInputs();
          }

          exclusiveInputEl.on('click touchend', function (e) {
            if (e.target.checked) {
              hideResponseInputs();
              enableResponseInput($(e.target));
            } else {
              showResponseInputs();
            }
          });
        });
      },

      _onLayout: function _onLayout(layout) {
        var self = this;
        this.$el.find('.label-text').shyphen();
      },

      _createResponseElements: function _createResponseElements() {
        var parentData = {
          input_type: this._object.input_type,
          input_id: this._object.input_id,
          element_id: this._object.element_id,
          ...(this._object?.error_message === '' && {
            answer: this._object.answer,
          }),
        };

        var responses = $.map(
          this._object.response_options,
          function (response) {
            var data = $.extend({}, response, parentData);
            return new ResponseItem(data);
          }
        );
        var $responses = $(
          $.map(responses, function (response) {
            return response.el;
          })
        );

        // TODO: Switch to a bootstrap-style document click handler
        $responses.find('.trailing-popup').yg_popup();

        $responses.find('input:radio, input:checkbox').uniform();

        // Since the jQuery Uniform library does not publish events for
        // change and focus, we can just listen for them on the original input elements
        // and add / remove our 'styling' classes as needed
        $responses
          .find('input:radio, input:checkbox')
          .on('change focus', function (e) {
            $('.response-label').removeClass('selected');

            $responses
              .find('.' + jQuery.uniform.defaults.checkedClass)
              .parents('.response-label')
              .addClass('selected');
          });
        if (jQuery.uniform && jQuery.uniform.defaults) {
          $responses
            .find('.' + jQuery.uniform.defaults.checkedClass)
            .parents('.response-label')
            .addClass('selected');
        } else {
          $responses
            .find('.uniform-checked')
            .parents('.response-label')
            .addClass('selected');
        }

        return $responses;
      },

      destroy: function destroy() {
        Single.__super__.destroy.call(this);
        this.layout.destroy();
      },
    },
    {
      types: ['single', 'language'],
      views: ['single'],
    }
  );

  Single.register();
  widgets.Single = Single;

  var Multiple = Single.extend(
    {
      // Used by 'displaymax'
      p_next: function p_next(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var cur = this._object.phase_pos;
        this.clear_required_message();
        if (this.required_is_satisfied()) {
          this.displaymax_soft_prompts = 0;
          if (cur + 1 > this._object.phase_max) {
            click_next({ key: ev.key, type: ev.type });
          } else {
            this._object.phase_pos++;
            this.setPagedVisibility();
            this.update_paged_buttons();
          }
        } else {
          this.display_required_message('.question-response-list');
        }
        return false;
      },

      // Used by 'displaymax'
      p_back: function p_back(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var cur = this._object.phase_pos;
        if (cur - 1 < 0) {
          click_back({ key: ev.key, type: ev.type });
        } else {
          this._object.phase_pos--;
          this.setPagedVisibility();
          this.update_paged_buttons();
        }
        return false;
      },

      // Used by 'displaymax'
      get_phase_rows: function get_phase_rows() {
        return $('.dynamic-row[data-index]');
      },

      /**
       * Further extends the base functionality to check whether
       * we are on the last response option before determining
       * satisfaction of the required flag
       *
       */
      required_is_satisfied: function required_is_satisfied() {
        var required = this._object.required === 'HARD' ? true : false;
        var soft_required = this._object.soft_required;
        // If we're on the last page, check for satisfaction of requirements
        // otherwise return `True` to continue paginating
        if (this._object.phase_pos + 1 > this._object.phase_max) {
          if (required || (soft_required && !this.displaymax_soft_prompts)) {
            if (this.selected_rows_count() < 1) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
    },
    {
      types: ['multiple'],
      views: ['multiple'],
    }
  );

  Multiple.register();
  widgets.Multiple = Multiple;

  var AutoAdvance = Single.extend(
    {
      render: function render() {
        AutoAdvance.__super__.render.call(this);
        this.$el.on('click', 'input', click_next);
      },
    },
    {
      types: ['autoadvance'],
      views: ['single'],
    }
  );

  AutoAdvance.register();
  widgets.AutoAdvance = AutoAdvance;

  var ResponseItem = (widgets.ResponseItem = Gryphon.BaseClass.extend({
    constructor: function ResponseItemConstructor(data) {
      var html = Gryphon.templates['response-item'](data);
      this.$el = $(html);
      this.el = this.$el[0];
      if (
        data.trailer &&
        data.trailer.type &&
        data.trailer.type.indexOf('open') === 0
      ) {
        this._attachEvents(data);
      }
    },

    _attachEvents: function _attachEvents(data) {
      var input;
      if (data.input_type === 'radio') {
        input = this.$el.find('#r-' + data.input_id + '-' + data.code);
      } else if (data.input_type === 'checkbox') {
        input = this.$el.find('#r-' + data.input_name);
      }
      var textInput = this.$el.find('#r-' + data.trailer.input_id);

      var toggleCheck = function (event) {
        if (event.type === 'click') {
          // We want to stop the default behaviour
          // of the parent label, which is to "run
          // synthetic click activation steps on
          // [its *first* labellable] element, as
          // if the element itself had been
          // triggered by the user". The effect of
          // this default behaviour is to give
          // focus immediately to the radio button
          // after clicking in the text input.
          event.preventDefault();
        }

        if (textInput.val()) {
          if (input.not(':checked')) {
            // Mark the parent node as "selected"
            $('.response-label').removeClass('selected');
            input.closest('.response-label').addClass('selected');

            // Mark the form element as selected/checked
            input.prop('checked', true);

            if (data.input_type === 'checkbox') {
              // #33398: Execute xor/exclusivity handler, if any
              var onclick = input[0].onclick;
              if ($.isFunction(onclick)) {
                onclick();
              }
            }
          }
        } else {
          if (
            input.is(':checked') &&
            // Improve UX; see #33529
            event.type !== 'click'
          ) {
            $('.response-label').removeClass('selected');
            input.prop('checked', false);
          }
        }

        // Updating all uniform() elements on the page
        // shouldn't be a heavy operation.
        $.uniform.update();
      };

      textInput.on('change keyup click', toggleCheck);

      var focusInput = function (event) {
        var inputTextToFocus = $(this).find('input[type="text"]');

        if (!inputTextToFocus.length) return;

        if (event.type === 'touchend') {
          event.stopImmediatePropagation();
          inputTextToFocus.focus().trigger('click');
        }
        inputTextToFocus.focus();
      };

      $(document).on(
        'click touchend',
        '.question-response-item.w-choice-base',
        focusInput
      );
    },
  }));
})((Gryphon.widgets = Gryphon.widgets || {}));
